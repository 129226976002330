import {
  getCurrentUser as amplifyGetCurrentUser,
  confirmResetPassword,
  confirmUserAttribute,
  fetchUserAttributes,
  resetPassword,
  signIn,
  signOut,
  updateUserAttribute,
} from "aws-amplify/auth";
// TODO: consider splitting this into a folder and splitting these functions into files
import Cookies from "js-cookie";

import { createUserDetail } from "../graphql/mutations";
// GraphQL
import { userDetailBySub } from "../graphql/queries";

import API from "./api";
import logger from "./logger";

async function getUserDetail(userId) {
  try {
    const variables = { sub: userId };
    const userData = await API.client.graphql({
      query: userDetailBySub,
      variables,
    });
    return userData.data.userDetailBySub.items[0];
  } catch (err) {
    window.analytics.track("JavaScript Error", {
      location: "getUserDetail",
      error: err,
    });
    logger.error("Auth.getUserDetail: error fetching data..", err);
  }
}

async function getCurrentUser() {
  try {
    const user = await amplifyGetCurrentUser();
    const userDetails = await getUserDetail(user.userId);
    if (!userDetails) {
      await signOut();
    }
    return userDetails;
  } catch (_error) {
    // TODO: send this to Sentry?
    // logger.error("error on getting current user: ", error, "error");
  }
}

async function createUserDetails() {
  try {
    const user = await amplifyGetCurrentUser();
    const userAttributes = await fetchUserAttributes();
    const createUserDetailInput = {
      type: "user",
      sub: user.userId,
      email: userAttributes.email,
      emailVerified: true,
      username: user.username,
    };
    const campaignCode = localStorage.getItem("campaign");
    if (campaignCode) createUserDetailInput.campaign = campaignCode;

    const userData = await API.client.graphql({
      query: createUserDetail,
      variables: {
        input: createUserDetailInput,
      },
    });
    return userData.data.createUserDetail;
  } catch (error) {
    logger.error("error on create user record: ", error, "error");
    return { error };
  }
}

const logIn = async ({ username, password, setUser }) => {
  try {
    const { isSignedIn, nextStep } = await signIn({ username, password });

    if (nextStep.signInStep === "DONE") {
      const cognitoUser = await amplifyGetCurrentUser();
      const userDetails = await getUserDetail(cognitoUser.userId);
      setUser(userDetails);
    }

    Cookies.set("atlistIsLoggedIn", true, { domain: ".atlist.com" });
    return { isSignedIn, nextStep };
  } catch (error) {
    logger.error("error on login: ", error, "error");
    return { error };
  }
};

const signUpAndLogIn = async ({ username, password, setUser }) => {
  try {
    const { isSignedIn, nextStep } = await signIn({ username, password });

    if (nextStep.signInStep === "DONE") {
      const userDetails = await createUserDetails();
      setUser(userDetails);
    }

    Cookies.set("atlistIsLoggedIn", true, { domain: ".atlist.com" });
    return { isSignedIn, nextStep };
  } catch (error) {
    logger.error("error on login: ", error, "error");
    return { error };
  }
};

const logOut = async (setUser) => {
  try {
    await signOut();
    setUser(null);
    Cookies.remove("atlistIsLoggedIn", { domain: ".atlist.com" });
    return { error: null };
  } catch (error) {
    logger.error("error on l: ", error, "error");
    return { error };
  }
};

const updateEmail = async (updatedEmail) => {
  try {
    const output = await updateUserAttribute({
      userAttribute: {
        attributeKey: "email",
        value: updatedEmail,
      },
    });
    return output;
  } catch (error) {
    return { error };
  }
};

const verifyUpdateEmail = async (confirmationCode) => {
  try {
    await confirmUserAttribute({
      userAttributeKey: "email",
      confirmationCode,
    });
    return { message: "DONE" };
  } catch (error) {
    return { error };
  }
};

const forgotPassword = async ({ username }) => {
  try {
    const result = await resetPassword({ username });
    const { attributeName, deliveryMedium, destination } =
      result.nextStep.codeDeliveryDetails;
    return { attributeName, deliveryMedium, destination };
  } catch (error) {
    // TODO: throw real error into segment
    return { error };
  }
};

const forgotPasswordSubmit = async ({
  username,
  confirmationCode,
  newPassword,
}) => {
  try {
    await confirmResetPassword({
      username,
      confirmationCode,
      newPassword,
    });
    return { message: "OK" };
  } catch (error) {
    logger.error("error on forgotPasswordSubmit: ", error, "error");
    return { error };
  }
};

export {
  getCurrentUser,
  logIn,
  logOut,
  updateEmail,
  verifyUpdateEmail,
  forgotPassword,
  forgotPasswordSubmit,
  signUpAndLogIn,
};
