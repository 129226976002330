import { isEmpty } from "lodash";
import React, { createContext, useContext } from "react";
import styled from "styled-components";

/**
  Lightbox

  An overlay component that can be called throughout the app. You can supply it a
  youtube video ID, or content to display, and is dismissable by clicking outside
  of the content.

  ### Parameters

  @param {string} youtube - provide an ID of a youtube link and will render the youtube embed code with autoplay enabled by default
  @param {node} content - provide react components to render within the lightbox
  @param {boolean} disableOverlayDismiss - the default behaviour of clicking the blurred overlay will close the lightbox. set this to true to disable this behaviour

  ### Usage

  onClick={() => {
    setLightbox({
      youtube: "xxx123", // optional
      disableOverlayDismiss: true, // optional
      content: ( // optional
        <div>
          <p>JSX can go here.</p>
          <AndSoCanComponents />
        </div>
      ),
    });
  }}

 */

/**
 * @type {React.Context<{options:{youtube?:String,content?:React.ReactNode,disableOverlayDismiss?:boolean} | null,setLightbox:(_:{youtube?:String,content?:React.ReactNode,disableOverlayDismiss?:boolean} | null)=>void}>}
 */
const LightboxContext = createContext(null);
export const useLightboxContext = () => {
  const ctx = useContext(LightboxContext);

  return ctx;
};

export const LightboxContextProvider = LightboxContext.Provider;

function Lightbox() {
  const { setLightbox, options } = useLightboxContext();
  return (
    <Overlay
      active={!isEmpty(options)}
      onClick={(e) => {
        if (
          !e.target.closest(".lightbox-content") &&
          !options.disableOverlayDismiss
        ) {
          setLightbox({});
        }
      }}
    >
      {options.youtube && (
        <iframe
          width="100%"
          height="100%"
          src={`https://www.youtube.com/embed/${options.youtube}?autoplay=1`}
          title="YouTube video player"
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
        />
      )}
      {options.content && (
        <LightboxContentContainer>
          <div className="lightbox-content" style={{ display: "inline-block" }}>
            {options.content}
          </div>
        </LightboxContentContainer>
      )}
    </Overlay>
  );
}

const LightboxContentContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Overlay = styled.div`
  position: fixed;
  overflow: scroll;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.5);
  backdrop-filter: blur(24px);
  padding: 8%;
  transition: all 400ms ease;
  ${(props) =>
    props.active
      ? "opacity: 100%; pointer-events: all; z-index: 999999;"
      : "opacity: 0; pointer-events: none; z-index: -1;"}
`;

export default Lightbox;
