import { generateClient } from "aws-amplify/api";

class Api {
  constructor() {
    this._client = null;
  }

  init() {
    this._client = generateClient();
  }

  get client() {
    return this._client;
  }
}

const proxy = new Api();

export default proxy;
