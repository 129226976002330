import PropTypes from "prop-types";
import React from "react";
import styled, { keyframes } from "styled-components";

import { centeredBoop } from "../../../styles/boops";

function Loading({ text }) {
  return (
    <Container>
      <svg
        width="684"
        height="625"
        viewBox="0 0 684 625"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M396.127 93.75L612.633 468.75C636.689 510.417 606.619 562.5 558.506 562.5H125.494C77.3811 562.5 47.3108 510.417 71.3671 468.75L287.873 93.75C311.93 52.0833 372.07 52.0833 396.127 93.75Z"
          fill="black"
          stroke="black"
          strokeWidth="125"
        />
      </svg>
      {text && <span>{text}</span>}
    </Container>
  );
}

Loading.propTypes = {
  text: PropTypes.string,
};

Loading.defaultProps = {
  text: "",
};

const fadeIn = keyframes`
from {
  opacity: 0;
}
to {
  opacity: 1;
}
`;

const Container = styled.div`
  top: 0;
  position: absolute;
  opacity: 0;
  display: flex;
  flex-direction: column;
  background-color: #dfd9d3;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
  animation: ${fadeIn} 300ms ease forwards;
  animation-delay: 700ms;
  span {
    margin-top: 8px;
    font-size: 12px;
    color: #bfb3a7;
  }
  svg {
    width: 40px;
    height: 40px;
    animation: ${centeredBoop} 2000ms ease infinite;
    path {
      fill: #bfb3a7;
      stroke: #bfb3a7;
    }
  }
`;

export default Loading;
