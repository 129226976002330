import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";
import { APIProvider } from "@vis.gl/react-google-maps";
import { Amplify } from "aws-amplify";
import { Hub } from "aws-amplify/utils";
import { navigate } from "gatsby";
import PropTypes from "prop-types";
import queryString from "query-string";
import React, { useEffect, useMemo, useState } from "react";
import Helmet from "react-helmet";
import styled, { createGlobalStyle } from "styled-components";

import amplifyconfig from "../../amplifyconfiguration.json";
import variables from "../../styles/variables";
import API from "../../utils/api";
import { getCurrentUser, logOut } from "../../utils/auth";
import isIframe from "../../utils/isIframe";
import AuthContext from "../contexts/AuthContext";
import ErrorScreen from "../elements/ErrorScreen";
import Loading from "../elements/Loading";
import Lightbox, { LightboxContextProvider } from "./Lightbox";
import Transition from "./transition";

import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";
import "../../styles/map-icons.min.css";
import "../../styles/normalize.css";

Amplify.configure(amplifyconfig);
API.init();

const GlobalStyle = createGlobalStyle`
  @supports (font-variation-settings: normal) {
    html {
      font-family:  sans-serif;
    }
  }
  html {
    font-family: Inter, sans-serif;
    text-rendering: optimizeLegibility;
    -webkit-font-smoothing: antialiased;
  }
  .pac-container {
    z-index: 100000000 !important;
  }
`;

Hub.listen("auth", ({ payload }) => {
  switch (payload.event) {
    case "signedIn":
      // navigate('/maps');
      // console.log('user have been signedIn successfully.');
      break;
    case "signedOut":
      // navigate('/login');
      // console.log('user have been signedOut successfully.');
      break;
    case "tokenRefresh":
      // console.log('auth tokens have been refreshed.');
      break;
    case "tokenRefresh_failure":
      // console.log('failure while refreshing auth tokens.');
      break;
    case "signInWithRedirect":
      // console.log('signInWithRedirect API has successfully been resolved.');
      break;
    case "signInWithRedirect_failure":
      // console.log('failure while trying to resolve signInWithRedirect API.');
      break;
    case "customOAuthState":
      // logger.info('custom state returned from CognitoHosted UI');
      break;
    default:
      break;
  }
});

const SENTRY_PROJECT_ID = "5939535";
const SENTRY_PUBLIC_KEY = "9a26e0bbd21c40588ae6d1881d61fd84";

const Layout = ({ children, location }) => {
  const [loading, setLoading] = useState(true);
  const [user, setUser] = useState(null);
  const userContext = useMemo(() => ({ user, setUser }), [user]);
  const [lightboxOptions, setLightbox] = useState({});

  useEffect(() => {
    async function getTheThing() {
      const currentUser = await getCurrentUser();
      setUser(currentUser);
      setLoading(false);
    }
    getTheThing();
  }, []);

  // https://docs.sentry.io/platforms/javascript/guides/react/#configure
  Sentry.init({
    dsn: `https://${SENTRY_PUBLIC_KEY}@o983647.ingest.sentry.io/${SENTRY_PROJECT_ID}`,
    integrations: [new Integrations.BrowserTracing()],

    // We recommend adjusting this value in production, or using tracesSampler
    // for finer control
    tracesSampleRate: 1.0,
  });

  if (loading) {
    return <Loading text="Loading Atlist" />;
  }

  if (user && user.banned) {
    return (
      <div
        style={{
          display: "flex",
          position: "absolute",
          backgroundColor: "#e74c3c",
          color: "white",
          width: "100%",
          height: "100%",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "column",
        }}
      >
        <p>This account has been suspended.</p>
        <p>If this was a mistake, contact support@atlist.com</p>
        <LogoutButton
          onClick={() => {
            logOut();
            navigate("/login");
          }}
        >
          Log Out
        </LogoutButton>
      </div>
    );
  }

  if (typeof window !== "undefined" && !isIframe()) {
    const { c: campaignCode } = queryString.parse(window.location.search);
    if (campaignCode) localStorage.setItem("campaign", campaignCode);
  }

  return (
    <LightboxContextProvider value={{ options: lightboxOptions, setLightbox }}>
      <GlobalStyle />
      <Helmet
        title="Atlist"
        meta={[
          { name: "description", content: "Create beautiful maps." },
          { name: "keywords", content: "atlist, maps" },
        ]}
      />

      <APIProvider apiKey={process.env.GATSBY_GOOGLE_MAPS_KEY}>
        <AuthContext.Provider value={userContext}>
          <Transition location={location}>
            <Content id="app-content">
              {React.cloneElement(children, {
                setLightbox,
              })}
            </Content>
          </Transition>
          <Lightbox options={lightboxOptions} setLightbox={setLightbox} />
        </AuthContext.Provider>
      </APIProvider>
    </LightboxContextProvider>
  );
};
const Content = styled.div`
  width: 100%;
  height: 100%;
  background-color: ${variables.defaultBackground};
`;

const LogoutButton = styled.div`
  border-bottom: 2px solid white;
`;

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

Layout.defaultProps = {};

export default Sentry.withErrorBoundary(Layout, {
  fallback: <ErrorScreen />,
});
