const lightGrey = "#eee";
const lightestGrey = "#f7f7f7";
const grey = "#DFD9D3";
const darkGrey = "#999";

export default {
  // layout
  gridStep: 8,
  gutter: 24,
  defaultWidth: 960,
  mobileWidth: 640,
  borderRadius: 8,
  defaultBorder: `1px solid ${grey}`,
  layoutBoxShadow: "0px 0px 0px 1px #fff",
  hoverBorder: `1px solid ${darkGrey}`,
  // fonts
  defaultFontSize: 16,
  mobileFontSize: 14,
  defaultFontWeight: 400,
  emphasizedFontWeight: 600,
  primaryFontFamily: "Inter, sans-serif",
  // background colors
  defaultBackground: "#FAF8F6",
  midDefaultBackground: "#EDE9E5",
  darkerDefaultBackground: "#DFD9D3",
  // colors
  defaultTextColor: "#000",
  primaryColor: "#2962FF",
  triadicPrimary: "rgb(255, 41, 98)",
  secondaryColor: "#777",
  defaultFontColor: "#263238",
  warning: "#f30061",
  lightFontColor: "#777",
  lightGrey,
  lightestGrey,
  grey,
  darkGrey,
  lightBlue: "#EAEFFE",
  lightestBlue: "#fafbff",
  danger: "#DD2C00",
  // effects
  lightBoxShadow: "0 0 16px rgba(0, 0, 0, 0.10)",
  defaultBoxShadow: "0 0 16px rgba(0, 0, 0, 0.25)",
  sunkenBoxShadow: "0 8px 18px rgba(0, 0, 0, 0.10)",
};
