import React from 'react';

import {
  TransitionGroup,
  Transition as ReactTransition,
} from 'react-transition-group';
import styled from 'styled-components';

const timeout = 300;

class Transition extends React.PureComponent {
  render() {
    const { children, location } = this.props;
    return (
      <TransitionGroup>
        <ReactTransition
          key={location.key}
          timeout={{
            enter: timeout,
            exit: timeout,
          }}
          onExit={node => {
            node.style.position = 'absolute';
            node.style.top = -1 * window.scrollY + 'px';
            node.style.width = '100%';
            node.style.height = '100%';
          }}
        >
          {status => {
            return (
              <TransitionContainer status={status}>
                {children}
              </TransitionContainer>
            );
          }}
        </ReactTransition>
      </TransitionGroup>
    );
  }
}

const TransitionContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  overflow-y: auto;
  overflow-x: hidden;
  ${props =>
    props.status === 'entering'
      ? `
      position: absolute; opacity: 0;
    `
      : null}
  ${props =>
    props.status === 'entered'
      ? `
      transition: all ${timeout}ms cubic-bezier(0.28, 0, 0.38, 1);
      opacity: 1;
    `
      : null}
  ${props =>
    props.status === 'exiting'
      ? `
      transition: all ${timeout}ms cubic-bezier(0.28, 0, 0.38, 1);
      opacity: 0;
    `
      : null}
`;

export default Transition;
