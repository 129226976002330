import { keyframes } from "styled-components";

const boop = keyframes`
  0% {
    transform: translate(-50%, -100%) scale(1);
  }
  6% {
    transform: translate(-50%, -100%) scale(1.2);
  }
  12% {
    transform: translate(-50%, -100%) scale(0.9);
  }
  18% {
    transform: translate(-50%, -100%) scale(1);
  }
`;

const hoverBoop = keyframes`
  0% {
    transform: translateY(-5px);
  }
  33% {
    transform: translateY(0px);
  }
  66% {
    transform: translateY(-5px);
  }
  78% {
    transform: translateY(0px);
  }
  100% {
    transform: translateY(-5px);
  }
`;

const centeredBoop = keyframes`
  0% {
    transform: scale(1);
  }
  6% {
    transform: scale(1.2);
  }
  12% {
    transform: scale(0.9);
  }
  18% {
    transform: scale(1);
  }
`;

export { boop, centeredBoop, hoverBoop };
