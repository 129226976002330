import React from "react";
import styled, { keyframes } from "styled-components";

function ErrorScreen() {
  return (
    <Container>
      <svg
        width="684"
        height="625"
        viewBox="0 0 684 625"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M396.127 93.75L612.633 468.75C636.689 510.417 606.619 562.5 558.506 562.5H125.494C77.3811 562.5 47.3108 510.417 71.3671 468.75L287.873 93.75C311.93 52.0833 372.07 52.0833 396.127 93.75Z"
          fill="black"
          stroke="black"
          strokeWidth="125"
        />
      </svg>
      <span>Oops! An Error Has Occured.</span>
      <Link onClick={() => location.reload()}>Reload</Link>
    </Container>
  );
}

const fadeIn = keyframes`
from {
  opacity: 0;
}
to {
  opacity: 1;
}
`;

const Link = styled.a`
  cursor: pointer;
  text-decoration: underline;
`;

const Container = styled.div`
  position: absolute;
  opacity: 0;
  display: flex;
  flex-direction: column;
  background-color: #dfd3d3;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
  animation: ${fadeIn} 300ms ease forwards;
  a {
    font-size: 16px;
    color: #2962ff;
  }
  span {
    margin-top: 16px;
    font-size: 16px;
    color: #9f7b7b;
  }
  svg {
    width: 40px;
    height: 40px;
    path {
      fill: #9f7b7b;
      stroke: #9f7b7b;
    }
  }
`;

export default ErrorScreen;
