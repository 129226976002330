/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const incrementDailyViews = /* GraphQL */ `
  mutation IncrementDailyViews($mapId: String) {
    incrementDailyViews(mapId: $mapId)
  }
`;
export const createMap = /* GraphQL */ `
  mutation CreateMap(
    $input: CreateMapInput!
    $condition: ModelMapConditionInput
  ) {
    createMap(input: $input, condition: $condition) {
      id
      owner
      type
      version
      categories {
        items {
          id
          categoryMapId
          collapsed
          itemsOrder
          markerBorder
          markerBorderColor
          markerColor
          markerCustomIcon
          markerCustomImage
          markerCustomStyle
          markerIcon
          markerIconColor
          markerNameBackgroundColor
          markerNameBackgroundOn
          markerNameColor
          markerNameOn
          markerNamePosition
          markerProgrammaticIconType
          markerShape
          markerSize
          name
          sort
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      events {
        items {
          id
          eventMapId
          createdAt
          name
          updatedAt
          __typename
        }
        nextToken
        scannedCount
        count
        __typename
      }
      markers {
        items {
          id
          markerMapId
          border
          borderColor
          buttonLink
          color
          createdAt
          customIcon
          customImage
          customStyle
          formattedAddress
          hideButton
          icon
          iconColor
          imagesOrder
          lat
          long
          markerNameBackgroundColor
          markerNameBackgroundOn
          markerNameColor
          markerNameOn
          markerNamePosition
          name
          notes
          placeId
          shape
          size
          tags
          updatedAt
          useCoordinates
          useHTML
          __typename
        }
        nextToken
        scannedCount
        count
        __typename
      }
      clusterColor
      clusterRadius
      clusterSize
      createdAt
      currentLocationText
      customCSS
      defaultRotate
      defaultTilt
      defaultZoom
      description
      descriptionHTML
      disableZoomToMarker
      embedDesktopHeight
      embedDesktopWidth
      embedMobileHeight
      embedMobileWidth
      filterPlaceholder
      fontPrimary
      fontSecondary
      fullscreenControl
      gestureHandling
      groupsCollapsed
      highlightSelectedMarker
      initialLatitude
      initialLongitude
      initialRotate
      initialTilt
      initialView
      initialZoom
      itemsOrder
      mapStyle
      mapStyleIcons
      mapStyleLabels
      mapStyleLandmarks
      mapStyleRoads
      mapTileType
      mapType
      markerBorder
      markerBorderColor
      markerColor
      markerCustomIcon
      markerCustomImage
      markerCustomStyle
      markerIcon
      markerIconColor
      markerNameBackgroundColor
      markerNameBackgroundOn
      markerNameColor
      markerNameOn
      markerNamePosition
      markerProgrammaticIconType
      markerShape
      markerSize
      modalAddressColor
      modalAddressLink
      modalAddressSize
      modalAddressVisible
      modalAddressWeight
      modalBackgroundColor
      modalButtonBackgroundColor
      modalButtonBorder
      modalButtonBorderColor
      modalButtonFontColor
      modalButtonFontSize
      modalButtonFontWeight
      modalButtonFullWidth
      modalButtonPadding
      modalButtonRounding
      modalButtons
      modalButtonText
      modalButtonType
      modalClose
      modalGoogleMapsButton
      modalGoogleMapsButtonText
      modalGoogleMapsButtonType
      modalImageHeight
      modalLinkButton
      modalLinkButtonText
      modalLinkSameWindow
      modalNoteColor
      modalNoteShowFull
      modalNoteSize
      modalNoteVisible
      modalNoteWeight
      modalPadding
      modalPosition
      modalRadius
      modalShadow
      modalTagBackgroundColor
      modalTagFontColor
      modalTagFontSize
      modalTagFullWidth
      modalTagPadding
      modalTagRounding
      modalTail
      modalTitleColor
      modalTitleSize
      modalTitleVisible
      modalTitleWeight
      modalTransparency
      modalWidth
      name
      overlayBorder
      overlayBorderSize
      overlayMargin
      overlayPadding
      overlayRounding
      overlayShadow
      overlayTransparency
      password
      permission
      rounding
      scaleControl
      searchBehavior
      searchPlaceholder
      showBicycling
      showBranding
      showCurrentLocation
      showDescription
      showDirectionsButton
      showLegendOverlay
      showLightbox
      showListFilter
      showListOverlay
      showModals
      showSearchbox
      showSidebar
      showSidebarAddress
      showTagsInModals
      showTagsInSidebar
      showTitle
      showTitleDescriptionOverlay
      showTraffic
      showTransit
      sidebarAccentColor
      sidebarBackgroundColor
      sidebarDescriptionSize
      sidebarDescriptionWeight
      sidebarPosition
      sidebarTitleSize
      sidebarTitleWeight
      sidebarWidth
      status
      streetViewControl
      tagBehavior
      tagsOrder
      titleImage
      unitSystem
      updatedAt
      zoomControl
      zoomHandling
      __typename
    }
  }
`;
export const updateMap = /* GraphQL */ `
  mutation UpdateMap(
    $input: UpdateMapInput!
    $condition: ModelMapConditionInput
  ) {
    updateMap(input: $input, condition: $condition) {
      id
      owner
      type
      version
      categories {
        items {
          id
          categoryMapId
          collapsed
          itemsOrder
          markerBorder
          markerBorderColor
          markerColor
          markerCustomIcon
          markerCustomImage
          markerCustomStyle
          markerIcon
          markerIconColor
          markerNameBackgroundColor
          markerNameBackgroundOn
          markerNameColor
          markerNameOn
          markerNamePosition
          markerProgrammaticIconType
          markerShape
          markerSize
          name
          sort
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      events {
        items {
          id
          eventMapId
          createdAt
          name
          updatedAt
          __typename
        }
        nextToken
        scannedCount
        count
        __typename
      }
      markers {
        items {
          id
          markerMapId
          border
          borderColor
          buttonLink
          color
          createdAt
          customIcon
          customImage
          customStyle
          formattedAddress
          hideButton
          icon
          iconColor
          imagesOrder
          lat
          long
          markerNameBackgroundColor
          markerNameBackgroundOn
          markerNameColor
          markerNameOn
          markerNamePosition
          name
          notes
          placeId
          shape
          size
          tags
          updatedAt
          useCoordinates
          useHTML
          __typename
        }
        nextToken
        scannedCount
        count
        __typename
      }
      clusterColor
      clusterRadius
      clusterSize
      createdAt
      currentLocationText
      customCSS
      defaultRotate
      defaultTilt
      defaultZoom
      description
      descriptionHTML
      disableZoomToMarker
      embedDesktopHeight
      embedDesktopWidth
      embedMobileHeight
      embedMobileWidth
      filterPlaceholder
      fontPrimary
      fontSecondary
      fullscreenControl
      gestureHandling
      groupsCollapsed
      highlightSelectedMarker
      initialLatitude
      initialLongitude
      initialRotate
      initialTilt
      initialView
      initialZoom
      itemsOrder
      mapStyle
      mapStyleIcons
      mapStyleLabels
      mapStyleLandmarks
      mapStyleRoads
      mapTileType
      mapType
      markerBorder
      markerBorderColor
      markerColor
      markerCustomIcon
      markerCustomImage
      markerCustomStyle
      markerIcon
      markerIconColor
      markerNameBackgroundColor
      markerNameBackgroundOn
      markerNameColor
      markerNameOn
      markerNamePosition
      markerProgrammaticIconType
      markerShape
      markerSize
      modalAddressColor
      modalAddressLink
      modalAddressSize
      modalAddressVisible
      modalAddressWeight
      modalBackgroundColor
      modalButtonBackgroundColor
      modalButtonBorder
      modalButtonBorderColor
      modalButtonFontColor
      modalButtonFontSize
      modalButtonFontWeight
      modalButtonFullWidth
      modalButtonPadding
      modalButtonRounding
      modalButtons
      modalButtonText
      modalButtonType
      modalClose
      modalGoogleMapsButton
      modalGoogleMapsButtonText
      modalGoogleMapsButtonType
      modalImageHeight
      modalLinkButton
      modalLinkButtonText
      modalLinkSameWindow
      modalNoteColor
      modalNoteShowFull
      modalNoteSize
      modalNoteVisible
      modalNoteWeight
      modalPadding
      modalPosition
      modalRadius
      modalShadow
      modalTagBackgroundColor
      modalTagFontColor
      modalTagFontSize
      modalTagFullWidth
      modalTagPadding
      modalTagRounding
      modalTail
      modalTitleColor
      modalTitleSize
      modalTitleVisible
      modalTitleWeight
      modalTransparency
      modalWidth
      name
      overlayBorder
      overlayBorderSize
      overlayMargin
      overlayPadding
      overlayRounding
      overlayShadow
      overlayTransparency
      password
      permission
      rounding
      scaleControl
      searchBehavior
      searchPlaceholder
      showBicycling
      showBranding
      showCurrentLocation
      showDescription
      showDirectionsButton
      showLegendOverlay
      showLightbox
      showListFilter
      showListOverlay
      showModals
      showSearchbox
      showSidebar
      showSidebarAddress
      showTagsInModals
      showTagsInSidebar
      showTitle
      showTitleDescriptionOverlay
      showTraffic
      showTransit
      sidebarAccentColor
      sidebarBackgroundColor
      sidebarDescriptionSize
      sidebarDescriptionWeight
      sidebarPosition
      sidebarTitleSize
      sidebarTitleWeight
      sidebarWidth
      status
      streetViewControl
      tagBehavior
      tagsOrder
      titleImage
      unitSystem
      updatedAt
      zoomControl
      zoomHandling
      __typename
    }
  }
`;
export const deleteMap = /* GraphQL */ `
  mutation DeleteMap(
    $input: DeleteMapInput!
    $condition: ModelMapConditionInput
  ) {
    deleteMap(input: $input, condition: $condition) {
      id
      owner
      type
      version
      categories {
        items {
          id
          categoryMapId
          collapsed
          itemsOrder
          markerBorder
          markerBorderColor
          markerColor
          markerCustomIcon
          markerCustomImage
          markerCustomStyle
          markerIcon
          markerIconColor
          markerNameBackgroundColor
          markerNameBackgroundOn
          markerNameColor
          markerNameOn
          markerNamePosition
          markerProgrammaticIconType
          markerShape
          markerSize
          name
          sort
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      events {
        items {
          id
          eventMapId
          createdAt
          name
          updatedAt
          __typename
        }
        nextToken
        scannedCount
        count
        __typename
      }
      markers {
        items {
          id
          markerMapId
          border
          borderColor
          buttonLink
          color
          createdAt
          customIcon
          customImage
          customStyle
          formattedAddress
          hideButton
          icon
          iconColor
          imagesOrder
          lat
          long
          markerNameBackgroundColor
          markerNameBackgroundOn
          markerNameColor
          markerNameOn
          markerNamePosition
          name
          notes
          placeId
          shape
          size
          tags
          updatedAt
          useCoordinates
          useHTML
          __typename
        }
        nextToken
        scannedCount
        count
        __typename
      }
      clusterColor
      clusterRadius
      clusterSize
      createdAt
      currentLocationText
      customCSS
      defaultRotate
      defaultTilt
      defaultZoom
      description
      descriptionHTML
      disableZoomToMarker
      embedDesktopHeight
      embedDesktopWidth
      embedMobileHeight
      embedMobileWidth
      filterPlaceholder
      fontPrimary
      fontSecondary
      fullscreenControl
      gestureHandling
      groupsCollapsed
      highlightSelectedMarker
      initialLatitude
      initialLongitude
      initialRotate
      initialTilt
      initialView
      initialZoom
      itemsOrder
      mapStyle
      mapStyleIcons
      mapStyleLabels
      mapStyleLandmarks
      mapStyleRoads
      mapTileType
      mapType
      markerBorder
      markerBorderColor
      markerColor
      markerCustomIcon
      markerCustomImage
      markerCustomStyle
      markerIcon
      markerIconColor
      markerNameBackgroundColor
      markerNameBackgroundOn
      markerNameColor
      markerNameOn
      markerNamePosition
      markerProgrammaticIconType
      markerShape
      markerSize
      modalAddressColor
      modalAddressLink
      modalAddressSize
      modalAddressVisible
      modalAddressWeight
      modalBackgroundColor
      modalButtonBackgroundColor
      modalButtonBorder
      modalButtonBorderColor
      modalButtonFontColor
      modalButtonFontSize
      modalButtonFontWeight
      modalButtonFullWidth
      modalButtonPadding
      modalButtonRounding
      modalButtons
      modalButtonText
      modalButtonType
      modalClose
      modalGoogleMapsButton
      modalGoogleMapsButtonText
      modalGoogleMapsButtonType
      modalImageHeight
      modalLinkButton
      modalLinkButtonText
      modalLinkSameWindow
      modalNoteColor
      modalNoteShowFull
      modalNoteSize
      modalNoteVisible
      modalNoteWeight
      modalPadding
      modalPosition
      modalRadius
      modalShadow
      modalTagBackgroundColor
      modalTagFontColor
      modalTagFontSize
      modalTagFullWidth
      modalTagPadding
      modalTagRounding
      modalTail
      modalTitleColor
      modalTitleSize
      modalTitleVisible
      modalTitleWeight
      modalTransparency
      modalWidth
      name
      overlayBorder
      overlayBorderSize
      overlayMargin
      overlayPadding
      overlayRounding
      overlayShadow
      overlayTransparency
      password
      permission
      rounding
      scaleControl
      searchBehavior
      searchPlaceholder
      showBicycling
      showBranding
      showCurrentLocation
      showDescription
      showDirectionsButton
      showLegendOverlay
      showLightbox
      showListFilter
      showListOverlay
      showModals
      showSearchbox
      showSidebar
      showSidebarAddress
      showTagsInModals
      showTagsInSidebar
      showTitle
      showTitleDescriptionOverlay
      showTraffic
      showTransit
      sidebarAccentColor
      sidebarBackgroundColor
      sidebarDescriptionSize
      sidebarDescriptionWeight
      sidebarPosition
      sidebarTitleSize
      sidebarTitleWeight
      sidebarWidth
      status
      streetViewControl
      tagBehavior
      tagsOrder
      titleImage
      unitSystem
      updatedAt
      zoomControl
      zoomHandling
      __typename
    }
  }
`;
export const createDailyViews = /* GraphQL */ `
  mutation CreateDailyViews(
    $input: CreateDailyViewsInput!
    $condition: ModelDailyViewsConditionInput
  ) {
    createDailyViews(input: $input, condition: $condition) {
      id
      mapId
      count
      createdAt
      day
      updatedAt
      __typename
    }
  }
`;
export const updateDailyViews = /* GraphQL */ `
  mutation UpdateDailyViews(
    $input: UpdateDailyViewsInput!
    $condition: ModelDailyViewsConditionInput
  ) {
    updateDailyViews(input: $input, condition: $condition) {
      id
      mapId
      count
      createdAt
      day
      updatedAt
      __typename
    }
  }
`;
export const deleteDailyViews = /* GraphQL */ `
  mutation DeleteDailyViews(
    $input: DeleteDailyViewsInput!
    $condition: ModelDailyViewsConditionInput
  ) {
    deleteDailyViews(input: $input, condition: $condition) {
      id
      mapId
      count
      createdAt
      day
      updatedAt
      __typename
    }
  }
`;
export const createEvent = /* GraphQL */ `
  mutation CreateEvent(
    $input: CreateEventInput!
    $condition: ModelEventConditionInput
  ) {
    createEvent(input: $input, condition: $condition) {
      id
      eventMapId
      map {
        id
        owner
        type
        version
        categories {
          nextToken
          __typename
        }
        events {
          nextToken
          scannedCount
          count
          __typename
        }
        markers {
          nextToken
          scannedCount
          count
          __typename
        }
        clusterColor
        clusterRadius
        clusterSize
        createdAt
        currentLocationText
        customCSS
        defaultRotate
        defaultTilt
        defaultZoom
        description
        descriptionHTML
        disableZoomToMarker
        embedDesktopHeight
        embedDesktopWidth
        embedMobileHeight
        embedMobileWidth
        filterPlaceholder
        fontPrimary
        fontSecondary
        fullscreenControl
        gestureHandling
        groupsCollapsed
        highlightSelectedMarker
        initialLatitude
        initialLongitude
        initialRotate
        initialTilt
        initialView
        initialZoom
        itemsOrder
        mapStyle
        mapStyleIcons
        mapStyleLabels
        mapStyleLandmarks
        mapStyleRoads
        mapTileType
        mapType
        markerBorder
        markerBorderColor
        markerColor
        markerCustomIcon
        markerCustomImage
        markerCustomStyle
        markerIcon
        markerIconColor
        markerNameBackgroundColor
        markerNameBackgroundOn
        markerNameColor
        markerNameOn
        markerNamePosition
        markerProgrammaticIconType
        markerShape
        markerSize
        modalAddressColor
        modalAddressLink
        modalAddressSize
        modalAddressVisible
        modalAddressWeight
        modalBackgroundColor
        modalButtonBackgroundColor
        modalButtonBorder
        modalButtonBorderColor
        modalButtonFontColor
        modalButtonFontSize
        modalButtonFontWeight
        modalButtonFullWidth
        modalButtonPadding
        modalButtonRounding
        modalButtons
        modalButtonText
        modalButtonType
        modalClose
        modalGoogleMapsButton
        modalGoogleMapsButtonText
        modalGoogleMapsButtonType
        modalImageHeight
        modalLinkButton
        modalLinkButtonText
        modalLinkSameWindow
        modalNoteColor
        modalNoteShowFull
        modalNoteSize
        modalNoteVisible
        modalNoteWeight
        modalPadding
        modalPosition
        modalRadius
        modalShadow
        modalTagBackgroundColor
        modalTagFontColor
        modalTagFontSize
        modalTagFullWidth
        modalTagPadding
        modalTagRounding
        modalTail
        modalTitleColor
        modalTitleSize
        modalTitleVisible
        modalTitleWeight
        modalTransparency
        modalWidth
        name
        overlayBorder
        overlayBorderSize
        overlayMargin
        overlayPadding
        overlayRounding
        overlayShadow
        overlayTransparency
        password
        permission
        rounding
        scaleControl
        searchBehavior
        searchPlaceholder
        showBicycling
        showBranding
        showCurrentLocation
        showDescription
        showDirectionsButton
        showLegendOverlay
        showLightbox
        showListFilter
        showListOverlay
        showModals
        showSearchbox
        showSidebar
        showSidebarAddress
        showTagsInModals
        showTagsInSidebar
        showTitle
        showTitleDescriptionOverlay
        showTraffic
        showTransit
        sidebarAccentColor
        sidebarBackgroundColor
        sidebarDescriptionSize
        sidebarDescriptionWeight
        sidebarPosition
        sidebarTitleSize
        sidebarTitleWeight
        sidebarWidth
        status
        streetViewControl
        tagBehavior
        tagsOrder
        titleImage
        unitSystem
        updatedAt
        zoomControl
        zoomHandling
        __typename
      }
      createdAt
      name
      updatedAt
      __typename
    }
  }
`;
export const updateEvent = /* GraphQL */ `
  mutation UpdateEvent(
    $input: UpdateEventInput!
    $condition: ModelEventConditionInput
  ) {
    updateEvent(input: $input, condition: $condition) {
      id
      eventMapId
      map {
        id
        owner
        type
        version
        categories {
          nextToken
          __typename
        }
        events {
          nextToken
          scannedCount
          count
          __typename
        }
        markers {
          nextToken
          scannedCount
          count
          __typename
        }
        clusterColor
        clusterRadius
        clusterSize
        createdAt
        currentLocationText
        customCSS
        defaultRotate
        defaultTilt
        defaultZoom
        description
        descriptionHTML
        disableZoomToMarker
        embedDesktopHeight
        embedDesktopWidth
        embedMobileHeight
        embedMobileWidth
        filterPlaceholder
        fontPrimary
        fontSecondary
        fullscreenControl
        gestureHandling
        groupsCollapsed
        highlightSelectedMarker
        initialLatitude
        initialLongitude
        initialRotate
        initialTilt
        initialView
        initialZoom
        itemsOrder
        mapStyle
        mapStyleIcons
        mapStyleLabels
        mapStyleLandmarks
        mapStyleRoads
        mapTileType
        mapType
        markerBorder
        markerBorderColor
        markerColor
        markerCustomIcon
        markerCustomImage
        markerCustomStyle
        markerIcon
        markerIconColor
        markerNameBackgroundColor
        markerNameBackgroundOn
        markerNameColor
        markerNameOn
        markerNamePosition
        markerProgrammaticIconType
        markerShape
        markerSize
        modalAddressColor
        modalAddressLink
        modalAddressSize
        modalAddressVisible
        modalAddressWeight
        modalBackgroundColor
        modalButtonBackgroundColor
        modalButtonBorder
        modalButtonBorderColor
        modalButtonFontColor
        modalButtonFontSize
        modalButtonFontWeight
        modalButtonFullWidth
        modalButtonPadding
        modalButtonRounding
        modalButtons
        modalButtonText
        modalButtonType
        modalClose
        modalGoogleMapsButton
        modalGoogleMapsButtonText
        modalGoogleMapsButtonType
        modalImageHeight
        modalLinkButton
        modalLinkButtonText
        modalLinkSameWindow
        modalNoteColor
        modalNoteShowFull
        modalNoteSize
        modalNoteVisible
        modalNoteWeight
        modalPadding
        modalPosition
        modalRadius
        modalShadow
        modalTagBackgroundColor
        modalTagFontColor
        modalTagFontSize
        modalTagFullWidth
        modalTagPadding
        modalTagRounding
        modalTail
        modalTitleColor
        modalTitleSize
        modalTitleVisible
        modalTitleWeight
        modalTransparency
        modalWidth
        name
        overlayBorder
        overlayBorderSize
        overlayMargin
        overlayPadding
        overlayRounding
        overlayShadow
        overlayTransparency
        password
        permission
        rounding
        scaleControl
        searchBehavior
        searchPlaceholder
        showBicycling
        showBranding
        showCurrentLocation
        showDescription
        showDirectionsButton
        showLegendOverlay
        showLightbox
        showListFilter
        showListOverlay
        showModals
        showSearchbox
        showSidebar
        showSidebarAddress
        showTagsInModals
        showTagsInSidebar
        showTitle
        showTitleDescriptionOverlay
        showTraffic
        showTransit
        sidebarAccentColor
        sidebarBackgroundColor
        sidebarDescriptionSize
        sidebarDescriptionWeight
        sidebarPosition
        sidebarTitleSize
        sidebarTitleWeight
        sidebarWidth
        status
        streetViewControl
        tagBehavior
        tagsOrder
        titleImage
        unitSystem
        updatedAt
        zoomControl
        zoomHandling
        __typename
      }
      createdAt
      name
      updatedAt
      __typename
    }
  }
`;
export const deleteEvent = /* GraphQL */ `
  mutation DeleteEvent(
    $input: DeleteEventInput!
    $condition: ModelEventConditionInput
  ) {
    deleteEvent(input: $input, condition: $condition) {
      id
      eventMapId
      map {
        id
        owner
        type
        version
        categories {
          nextToken
          __typename
        }
        events {
          nextToken
          scannedCount
          count
          __typename
        }
        markers {
          nextToken
          scannedCount
          count
          __typename
        }
        clusterColor
        clusterRadius
        clusterSize
        createdAt
        currentLocationText
        customCSS
        defaultRotate
        defaultTilt
        defaultZoom
        description
        descriptionHTML
        disableZoomToMarker
        embedDesktopHeight
        embedDesktopWidth
        embedMobileHeight
        embedMobileWidth
        filterPlaceholder
        fontPrimary
        fontSecondary
        fullscreenControl
        gestureHandling
        groupsCollapsed
        highlightSelectedMarker
        initialLatitude
        initialLongitude
        initialRotate
        initialTilt
        initialView
        initialZoom
        itemsOrder
        mapStyle
        mapStyleIcons
        mapStyleLabels
        mapStyleLandmarks
        mapStyleRoads
        mapTileType
        mapType
        markerBorder
        markerBorderColor
        markerColor
        markerCustomIcon
        markerCustomImage
        markerCustomStyle
        markerIcon
        markerIconColor
        markerNameBackgroundColor
        markerNameBackgroundOn
        markerNameColor
        markerNameOn
        markerNamePosition
        markerProgrammaticIconType
        markerShape
        markerSize
        modalAddressColor
        modalAddressLink
        modalAddressSize
        modalAddressVisible
        modalAddressWeight
        modalBackgroundColor
        modalButtonBackgroundColor
        modalButtonBorder
        modalButtonBorderColor
        modalButtonFontColor
        modalButtonFontSize
        modalButtonFontWeight
        modalButtonFullWidth
        modalButtonPadding
        modalButtonRounding
        modalButtons
        modalButtonText
        modalButtonType
        modalClose
        modalGoogleMapsButton
        modalGoogleMapsButtonText
        modalGoogleMapsButtonType
        modalImageHeight
        modalLinkButton
        modalLinkButtonText
        modalLinkSameWindow
        modalNoteColor
        modalNoteShowFull
        modalNoteSize
        modalNoteVisible
        modalNoteWeight
        modalPadding
        modalPosition
        modalRadius
        modalShadow
        modalTagBackgroundColor
        modalTagFontColor
        modalTagFontSize
        modalTagFullWidth
        modalTagPadding
        modalTagRounding
        modalTail
        modalTitleColor
        modalTitleSize
        modalTitleVisible
        modalTitleWeight
        modalTransparency
        modalWidth
        name
        overlayBorder
        overlayBorderSize
        overlayMargin
        overlayPadding
        overlayRounding
        overlayShadow
        overlayTransparency
        password
        permission
        rounding
        scaleControl
        searchBehavior
        searchPlaceholder
        showBicycling
        showBranding
        showCurrentLocation
        showDescription
        showDirectionsButton
        showLegendOverlay
        showLightbox
        showListFilter
        showListOverlay
        showModals
        showSearchbox
        showSidebar
        showSidebarAddress
        showTagsInModals
        showTagsInSidebar
        showTitle
        showTitleDescriptionOverlay
        showTraffic
        showTransit
        sidebarAccentColor
        sidebarBackgroundColor
        sidebarDescriptionSize
        sidebarDescriptionWeight
        sidebarPosition
        sidebarTitleSize
        sidebarTitleWeight
        sidebarWidth
        status
        streetViewControl
        tagBehavior
        tagsOrder
        titleImage
        unitSystem
        updatedAt
        zoomControl
        zoomHandling
        __typename
      }
      createdAt
      name
      updatedAt
      __typename
    }
  }
`;
export const createCategory = /* GraphQL */ `
  mutation CreateCategory(
    $input: CreateCategoryInput!
    $condition: ModelCategoryConditionInput
  ) {
    createCategory(input: $input, condition: $condition) {
      id
      categoryMapId
      map {
        id
        owner
        type
        version
        categories {
          nextToken
          __typename
        }
        events {
          nextToken
          scannedCount
          count
          __typename
        }
        markers {
          nextToken
          scannedCount
          count
          __typename
        }
        clusterColor
        clusterRadius
        clusterSize
        createdAt
        currentLocationText
        customCSS
        defaultRotate
        defaultTilt
        defaultZoom
        description
        descriptionHTML
        disableZoomToMarker
        embedDesktopHeight
        embedDesktopWidth
        embedMobileHeight
        embedMobileWidth
        filterPlaceholder
        fontPrimary
        fontSecondary
        fullscreenControl
        gestureHandling
        groupsCollapsed
        highlightSelectedMarker
        initialLatitude
        initialLongitude
        initialRotate
        initialTilt
        initialView
        initialZoom
        itemsOrder
        mapStyle
        mapStyleIcons
        mapStyleLabels
        mapStyleLandmarks
        mapStyleRoads
        mapTileType
        mapType
        markerBorder
        markerBorderColor
        markerColor
        markerCustomIcon
        markerCustomImage
        markerCustomStyle
        markerIcon
        markerIconColor
        markerNameBackgroundColor
        markerNameBackgroundOn
        markerNameColor
        markerNameOn
        markerNamePosition
        markerProgrammaticIconType
        markerShape
        markerSize
        modalAddressColor
        modalAddressLink
        modalAddressSize
        modalAddressVisible
        modalAddressWeight
        modalBackgroundColor
        modalButtonBackgroundColor
        modalButtonBorder
        modalButtonBorderColor
        modalButtonFontColor
        modalButtonFontSize
        modalButtonFontWeight
        modalButtonFullWidth
        modalButtonPadding
        modalButtonRounding
        modalButtons
        modalButtonText
        modalButtonType
        modalClose
        modalGoogleMapsButton
        modalGoogleMapsButtonText
        modalGoogleMapsButtonType
        modalImageHeight
        modalLinkButton
        modalLinkButtonText
        modalLinkSameWindow
        modalNoteColor
        modalNoteShowFull
        modalNoteSize
        modalNoteVisible
        modalNoteWeight
        modalPadding
        modalPosition
        modalRadius
        modalShadow
        modalTagBackgroundColor
        modalTagFontColor
        modalTagFontSize
        modalTagFullWidth
        modalTagPadding
        modalTagRounding
        modalTail
        modalTitleColor
        modalTitleSize
        modalTitleVisible
        modalTitleWeight
        modalTransparency
        modalWidth
        name
        overlayBorder
        overlayBorderSize
        overlayMargin
        overlayPadding
        overlayRounding
        overlayShadow
        overlayTransparency
        password
        permission
        rounding
        scaleControl
        searchBehavior
        searchPlaceholder
        showBicycling
        showBranding
        showCurrentLocation
        showDescription
        showDirectionsButton
        showLegendOverlay
        showLightbox
        showListFilter
        showListOverlay
        showModals
        showSearchbox
        showSidebar
        showSidebarAddress
        showTagsInModals
        showTagsInSidebar
        showTitle
        showTitleDescriptionOverlay
        showTraffic
        showTransit
        sidebarAccentColor
        sidebarBackgroundColor
        sidebarDescriptionSize
        sidebarDescriptionWeight
        sidebarPosition
        sidebarTitleSize
        sidebarTitleWeight
        sidebarWidth
        status
        streetViewControl
        tagBehavior
        tagsOrder
        titleImage
        unitSystem
        updatedAt
        zoomControl
        zoomHandling
        __typename
      }
      collapsed
      itemsOrder
      markerBorder
      markerBorderColor
      markerColor
      markerCustomIcon
      markerCustomImage
      markerCustomStyle
      markerIcon
      markerIconColor
      markerNameBackgroundColor
      markerNameBackgroundOn
      markerNameColor
      markerNameOn
      markerNamePosition
      markerProgrammaticIconType
      markerShape
      markerSize
      name
      sort
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateCategory = /* GraphQL */ `
  mutation UpdateCategory(
    $input: UpdateCategoryInput!
    $condition: ModelCategoryConditionInput
  ) {
    updateCategory(input: $input, condition: $condition) {
      id
      categoryMapId
      map {
        id
        owner
        type
        version
        categories {
          nextToken
          __typename
        }
        events {
          nextToken
          scannedCount
          count
          __typename
        }
        markers {
          nextToken
          scannedCount
          count
          __typename
        }
        clusterColor
        clusterRadius
        clusterSize
        createdAt
        currentLocationText
        customCSS
        defaultRotate
        defaultTilt
        defaultZoom
        description
        descriptionHTML
        disableZoomToMarker
        embedDesktopHeight
        embedDesktopWidth
        embedMobileHeight
        embedMobileWidth
        filterPlaceholder
        fontPrimary
        fontSecondary
        fullscreenControl
        gestureHandling
        groupsCollapsed
        highlightSelectedMarker
        initialLatitude
        initialLongitude
        initialRotate
        initialTilt
        initialView
        initialZoom
        itemsOrder
        mapStyle
        mapStyleIcons
        mapStyleLabels
        mapStyleLandmarks
        mapStyleRoads
        mapTileType
        mapType
        markerBorder
        markerBorderColor
        markerColor
        markerCustomIcon
        markerCustomImage
        markerCustomStyle
        markerIcon
        markerIconColor
        markerNameBackgroundColor
        markerNameBackgroundOn
        markerNameColor
        markerNameOn
        markerNamePosition
        markerProgrammaticIconType
        markerShape
        markerSize
        modalAddressColor
        modalAddressLink
        modalAddressSize
        modalAddressVisible
        modalAddressWeight
        modalBackgroundColor
        modalButtonBackgroundColor
        modalButtonBorder
        modalButtonBorderColor
        modalButtonFontColor
        modalButtonFontSize
        modalButtonFontWeight
        modalButtonFullWidth
        modalButtonPadding
        modalButtonRounding
        modalButtons
        modalButtonText
        modalButtonType
        modalClose
        modalGoogleMapsButton
        modalGoogleMapsButtonText
        modalGoogleMapsButtonType
        modalImageHeight
        modalLinkButton
        modalLinkButtonText
        modalLinkSameWindow
        modalNoteColor
        modalNoteShowFull
        modalNoteSize
        modalNoteVisible
        modalNoteWeight
        modalPadding
        modalPosition
        modalRadius
        modalShadow
        modalTagBackgroundColor
        modalTagFontColor
        modalTagFontSize
        modalTagFullWidth
        modalTagPadding
        modalTagRounding
        modalTail
        modalTitleColor
        modalTitleSize
        modalTitleVisible
        modalTitleWeight
        modalTransparency
        modalWidth
        name
        overlayBorder
        overlayBorderSize
        overlayMargin
        overlayPadding
        overlayRounding
        overlayShadow
        overlayTransparency
        password
        permission
        rounding
        scaleControl
        searchBehavior
        searchPlaceholder
        showBicycling
        showBranding
        showCurrentLocation
        showDescription
        showDirectionsButton
        showLegendOverlay
        showLightbox
        showListFilter
        showListOverlay
        showModals
        showSearchbox
        showSidebar
        showSidebarAddress
        showTagsInModals
        showTagsInSidebar
        showTitle
        showTitleDescriptionOverlay
        showTraffic
        showTransit
        sidebarAccentColor
        sidebarBackgroundColor
        sidebarDescriptionSize
        sidebarDescriptionWeight
        sidebarPosition
        sidebarTitleSize
        sidebarTitleWeight
        sidebarWidth
        status
        streetViewControl
        tagBehavior
        tagsOrder
        titleImage
        unitSystem
        updatedAt
        zoomControl
        zoomHandling
        __typename
      }
      collapsed
      itemsOrder
      markerBorder
      markerBorderColor
      markerColor
      markerCustomIcon
      markerCustomImage
      markerCustomStyle
      markerIcon
      markerIconColor
      markerNameBackgroundColor
      markerNameBackgroundOn
      markerNameColor
      markerNameOn
      markerNamePosition
      markerProgrammaticIconType
      markerShape
      markerSize
      name
      sort
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteCategory = /* GraphQL */ `
  mutation DeleteCategory(
    $input: DeleteCategoryInput!
    $condition: ModelCategoryConditionInput
  ) {
    deleteCategory(input: $input, condition: $condition) {
      id
      categoryMapId
      map {
        id
        owner
        type
        version
        categories {
          nextToken
          __typename
        }
        events {
          nextToken
          scannedCount
          count
          __typename
        }
        markers {
          nextToken
          scannedCount
          count
          __typename
        }
        clusterColor
        clusterRadius
        clusterSize
        createdAt
        currentLocationText
        customCSS
        defaultRotate
        defaultTilt
        defaultZoom
        description
        descriptionHTML
        disableZoomToMarker
        embedDesktopHeight
        embedDesktopWidth
        embedMobileHeight
        embedMobileWidth
        filterPlaceholder
        fontPrimary
        fontSecondary
        fullscreenControl
        gestureHandling
        groupsCollapsed
        highlightSelectedMarker
        initialLatitude
        initialLongitude
        initialRotate
        initialTilt
        initialView
        initialZoom
        itemsOrder
        mapStyle
        mapStyleIcons
        mapStyleLabels
        mapStyleLandmarks
        mapStyleRoads
        mapTileType
        mapType
        markerBorder
        markerBorderColor
        markerColor
        markerCustomIcon
        markerCustomImage
        markerCustomStyle
        markerIcon
        markerIconColor
        markerNameBackgroundColor
        markerNameBackgroundOn
        markerNameColor
        markerNameOn
        markerNamePosition
        markerProgrammaticIconType
        markerShape
        markerSize
        modalAddressColor
        modalAddressLink
        modalAddressSize
        modalAddressVisible
        modalAddressWeight
        modalBackgroundColor
        modalButtonBackgroundColor
        modalButtonBorder
        modalButtonBorderColor
        modalButtonFontColor
        modalButtonFontSize
        modalButtonFontWeight
        modalButtonFullWidth
        modalButtonPadding
        modalButtonRounding
        modalButtons
        modalButtonText
        modalButtonType
        modalClose
        modalGoogleMapsButton
        modalGoogleMapsButtonText
        modalGoogleMapsButtonType
        modalImageHeight
        modalLinkButton
        modalLinkButtonText
        modalLinkSameWindow
        modalNoteColor
        modalNoteShowFull
        modalNoteSize
        modalNoteVisible
        modalNoteWeight
        modalPadding
        modalPosition
        modalRadius
        modalShadow
        modalTagBackgroundColor
        modalTagFontColor
        modalTagFontSize
        modalTagFullWidth
        modalTagPadding
        modalTagRounding
        modalTail
        modalTitleColor
        modalTitleSize
        modalTitleVisible
        modalTitleWeight
        modalTransparency
        modalWidth
        name
        overlayBorder
        overlayBorderSize
        overlayMargin
        overlayPadding
        overlayRounding
        overlayShadow
        overlayTransparency
        password
        permission
        rounding
        scaleControl
        searchBehavior
        searchPlaceholder
        showBicycling
        showBranding
        showCurrentLocation
        showDescription
        showDirectionsButton
        showLegendOverlay
        showLightbox
        showListFilter
        showListOverlay
        showModals
        showSearchbox
        showSidebar
        showSidebarAddress
        showTagsInModals
        showTagsInSidebar
        showTitle
        showTitleDescriptionOverlay
        showTraffic
        showTransit
        sidebarAccentColor
        sidebarBackgroundColor
        sidebarDescriptionSize
        sidebarDescriptionWeight
        sidebarPosition
        sidebarTitleSize
        sidebarTitleWeight
        sidebarWidth
        status
        streetViewControl
        tagBehavior
        tagsOrder
        titleImage
        unitSystem
        updatedAt
        zoomControl
        zoomHandling
        __typename
      }
      collapsed
      itemsOrder
      markerBorder
      markerBorderColor
      markerColor
      markerCustomIcon
      markerCustomImage
      markerCustomStyle
      markerIcon
      markerIconColor
      markerNameBackgroundColor
      markerNameBackgroundOn
      markerNameColor
      markerNameOn
      markerNamePosition
      markerProgrammaticIconType
      markerShape
      markerSize
      name
      sort
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createMarker = /* GraphQL */ `
  mutation CreateMarker(
    $input: CreateMarkerInput!
    $condition: ModelMarkerConditionInput
  ) {
    createMarker(input: $input, condition: $condition) {
      id
      markerMapId
      map {
        id
        owner
        type
        version
        categories {
          nextToken
          __typename
        }
        events {
          nextToken
          scannedCount
          count
          __typename
        }
        markers {
          nextToken
          scannedCount
          count
          __typename
        }
        clusterColor
        clusterRadius
        clusterSize
        createdAt
        currentLocationText
        customCSS
        defaultRotate
        defaultTilt
        defaultZoom
        description
        descriptionHTML
        disableZoomToMarker
        embedDesktopHeight
        embedDesktopWidth
        embedMobileHeight
        embedMobileWidth
        filterPlaceholder
        fontPrimary
        fontSecondary
        fullscreenControl
        gestureHandling
        groupsCollapsed
        highlightSelectedMarker
        initialLatitude
        initialLongitude
        initialRotate
        initialTilt
        initialView
        initialZoom
        itemsOrder
        mapStyle
        mapStyleIcons
        mapStyleLabels
        mapStyleLandmarks
        mapStyleRoads
        mapTileType
        mapType
        markerBorder
        markerBorderColor
        markerColor
        markerCustomIcon
        markerCustomImage
        markerCustomStyle
        markerIcon
        markerIconColor
        markerNameBackgroundColor
        markerNameBackgroundOn
        markerNameColor
        markerNameOn
        markerNamePosition
        markerProgrammaticIconType
        markerShape
        markerSize
        modalAddressColor
        modalAddressLink
        modalAddressSize
        modalAddressVisible
        modalAddressWeight
        modalBackgroundColor
        modalButtonBackgroundColor
        modalButtonBorder
        modalButtonBorderColor
        modalButtonFontColor
        modalButtonFontSize
        modalButtonFontWeight
        modalButtonFullWidth
        modalButtonPadding
        modalButtonRounding
        modalButtons
        modalButtonText
        modalButtonType
        modalClose
        modalGoogleMapsButton
        modalGoogleMapsButtonText
        modalGoogleMapsButtonType
        modalImageHeight
        modalLinkButton
        modalLinkButtonText
        modalLinkSameWindow
        modalNoteColor
        modalNoteShowFull
        modalNoteSize
        modalNoteVisible
        modalNoteWeight
        modalPadding
        modalPosition
        modalRadius
        modalShadow
        modalTagBackgroundColor
        modalTagFontColor
        modalTagFontSize
        modalTagFullWidth
        modalTagPadding
        modalTagRounding
        modalTail
        modalTitleColor
        modalTitleSize
        modalTitleVisible
        modalTitleWeight
        modalTransparency
        modalWidth
        name
        overlayBorder
        overlayBorderSize
        overlayMargin
        overlayPadding
        overlayRounding
        overlayShadow
        overlayTransparency
        password
        permission
        rounding
        scaleControl
        searchBehavior
        searchPlaceholder
        showBicycling
        showBranding
        showCurrentLocation
        showDescription
        showDirectionsButton
        showLegendOverlay
        showLightbox
        showListFilter
        showListOverlay
        showModals
        showSearchbox
        showSidebar
        showSidebarAddress
        showTagsInModals
        showTagsInSidebar
        showTitle
        showTitleDescriptionOverlay
        showTraffic
        showTransit
        sidebarAccentColor
        sidebarBackgroundColor
        sidebarDescriptionSize
        sidebarDescriptionWeight
        sidebarPosition
        sidebarTitleSize
        sidebarTitleWeight
        sidebarWidth
        status
        streetViewControl
        tagBehavior
        tagsOrder
        titleImage
        unitSystem
        updatedAt
        zoomControl
        zoomHandling
        __typename
      }
      comments {
        items {
          id
          owner
          content
          createdAt
          updatedAt
          markerCommentsId
          __typename
        }
        nextToken
        __typename
      }
      images {
        items {
          id
          imageMarkerId
          image
          name
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      border
      borderColor
      buttonLink
      color
      createdAt
      customIcon
      customImage
      customStyle
      formattedAddress
      hideButton
      icon
      iconColor
      imagesOrder
      lat
      long
      markerNameBackgroundColor
      markerNameBackgroundOn
      markerNameColor
      markerNameOn
      markerNamePosition
      name
      notes
      placeId
      shape
      size
      tags
      updatedAt
      useCoordinates
      useHTML
      __typename
    }
  }
`;
export const updateMarker = /* GraphQL */ `
  mutation UpdateMarker(
    $input: UpdateMarkerInput!
    $condition: ModelMarkerConditionInput
  ) {
    updateMarker(input: $input, condition: $condition) {
      id
      markerMapId
      map {
        id
        owner
        type
        version
        categories {
          nextToken
          __typename
        }
        events {
          nextToken
          scannedCount
          count
          __typename
        }
        markers {
          nextToken
          scannedCount
          count
          __typename
        }
        clusterColor
        clusterRadius
        clusterSize
        createdAt
        currentLocationText
        customCSS
        defaultRotate
        defaultTilt
        defaultZoom
        description
        descriptionHTML
        disableZoomToMarker
        embedDesktopHeight
        embedDesktopWidth
        embedMobileHeight
        embedMobileWidth
        filterPlaceholder
        fontPrimary
        fontSecondary
        fullscreenControl
        gestureHandling
        groupsCollapsed
        highlightSelectedMarker
        initialLatitude
        initialLongitude
        initialRotate
        initialTilt
        initialView
        initialZoom
        itemsOrder
        mapStyle
        mapStyleIcons
        mapStyleLabels
        mapStyleLandmarks
        mapStyleRoads
        mapTileType
        mapType
        markerBorder
        markerBorderColor
        markerColor
        markerCustomIcon
        markerCustomImage
        markerCustomStyle
        markerIcon
        markerIconColor
        markerNameBackgroundColor
        markerNameBackgroundOn
        markerNameColor
        markerNameOn
        markerNamePosition
        markerProgrammaticIconType
        markerShape
        markerSize
        modalAddressColor
        modalAddressLink
        modalAddressSize
        modalAddressVisible
        modalAddressWeight
        modalBackgroundColor
        modalButtonBackgroundColor
        modalButtonBorder
        modalButtonBorderColor
        modalButtonFontColor
        modalButtonFontSize
        modalButtonFontWeight
        modalButtonFullWidth
        modalButtonPadding
        modalButtonRounding
        modalButtons
        modalButtonText
        modalButtonType
        modalClose
        modalGoogleMapsButton
        modalGoogleMapsButtonText
        modalGoogleMapsButtonType
        modalImageHeight
        modalLinkButton
        modalLinkButtonText
        modalLinkSameWindow
        modalNoteColor
        modalNoteShowFull
        modalNoteSize
        modalNoteVisible
        modalNoteWeight
        modalPadding
        modalPosition
        modalRadius
        modalShadow
        modalTagBackgroundColor
        modalTagFontColor
        modalTagFontSize
        modalTagFullWidth
        modalTagPadding
        modalTagRounding
        modalTail
        modalTitleColor
        modalTitleSize
        modalTitleVisible
        modalTitleWeight
        modalTransparency
        modalWidth
        name
        overlayBorder
        overlayBorderSize
        overlayMargin
        overlayPadding
        overlayRounding
        overlayShadow
        overlayTransparency
        password
        permission
        rounding
        scaleControl
        searchBehavior
        searchPlaceholder
        showBicycling
        showBranding
        showCurrentLocation
        showDescription
        showDirectionsButton
        showLegendOverlay
        showLightbox
        showListFilter
        showListOverlay
        showModals
        showSearchbox
        showSidebar
        showSidebarAddress
        showTagsInModals
        showTagsInSidebar
        showTitle
        showTitleDescriptionOverlay
        showTraffic
        showTransit
        sidebarAccentColor
        sidebarBackgroundColor
        sidebarDescriptionSize
        sidebarDescriptionWeight
        sidebarPosition
        sidebarTitleSize
        sidebarTitleWeight
        sidebarWidth
        status
        streetViewControl
        tagBehavior
        tagsOrder
        titleImage
        unitSystem
        updatedAt
        zoomControl
        zoomHandling
        __typename
      }
      comments {
        items {
          id
          owner
          content
          createdAt
          updatedAt
          markerCommentsId
          __typename
        }
        nextToken
        __typename
      }
      images {
        items {
          id
          imageMarkerId
          image
          name
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      border
      borderColor
      buttonLink
      color
      createdAt
      customIcon
      customImage
      customStyle
      formattedAddress
      hideButton
      icon
      iconColor
      imagesOrder
      lat
      long
      markerNameBackgroundColor
      markerNameBackgroundOn
      markerNameColor
      markerNameOn
      markerNamePosition
      name
      notes
      placeId
      shape
      size
      tags
      updatedAt
      useCoordinates
      useHTML
      __typename
    }
  }
`;
export const deleteMarker = /* GraphQL */ `
  mutation DeleteMarker(
    $input: DeleteMarkerInput!
    $condition: ModelMarkerConditionInput
  ) {
    deleteMarker(input: $input, condition: $condition) {
      id
      markerMapId
      map {
        id
        owner
        type
        version
        categories {
          nextToken
          __typename
        }
        events {
          nextToken
          scannedCount
          count
          __typename
        }
        markers {
          nextToken
          scannedCount
          count
          __typename
        }
        clusterColor
        clusterRadius
        clusterSize
        createdAt
        currentLocationText
        customCSS
        defaultRotate
        defaultTilt
        defaultZoom
        description
        descriptionHTML
        disableZoomToMarker
        embedDesktopHeight
        embedDesktopWidth
        embedMobileHeight
        embedMobileWidth
        filterPlaceholder
        fontPrimary
        fontSecondary
        fullscreenControl
        gestureHandling
        groupsCollapsed
        highlightSelectedMarker
        initialLatitude
        initialLongitude
        initialRotate
        initialTilt
        initialView
        initialZoom
        itemsOrder
        mapStyle
        mapStyleIcons
        mapStyleLabels
        mapStyleLandmarks
        mapStyleRoads
        mapTileType
        mapType
        markerBorder
        markerBorderColor
        markerColor
        markerCustomIcon
        markerCustomImage
        markerCustomStyle
        markerIcon
        markerIconColor
        markerNameBackgroundColor
        markerNameBackgroundOn
        markerNameColor
        markerNameOn
        markerNamePosition
        markerProgrammaticIconType
        markerShape
        markerSize
        modalAddressColor
        modalAddressLink
        modalAddressSize
        modalAddressVisible
        modalAddressWeight
        modalBackgroundColor
        modalButtonBackgroundColor
        modalButtonBorder
        modalButtonBorderColor
        modalButtonFontColor
        modalButtonFontSize
        modalButtonFontWeight
        modalButtonFullWidth
        modalButtonPadding
        modalButtonRounding
        modalButtons
        modalButtonText
        modalButtonType
        modalClose
        modalGoogleMapsButton
        modalGoogleMapsButtonText
        modalGoogleMapsButtonType
        modalImageHeight
        modalLinkButton
        modalLinkButtonText
        modalLinkSameWindow
        modalNoteColor
        modalNoteShowFull
        modalNoteSize
        modalNoteVisible
        modalNoteWeight
        modalPadding
        modalPosition
        modalRadius
        modalShadow
        modalTagBackgroundColor
        modalTagFontColor
        modalTagFontSize
        modalTagFullWidth
        modalTagPadding
        modalTagRounding
        modalTail
        modalTitleColor
        modalTitleSize
        modalTitleVisible
        modalTitleWeight
        modalTransparency
        modalWidth
        name
        overlayBorder
        overlayBorderSize
        overlayMargin
        overlayPadding
        overlayRounding
        overlayShadow
        overlayTransparency
        password
        permission
        rounding
        scaleControl
        searchBehavior
        searchPlaceholder
        showBicycling
        showBranding
        showCurrentLocation
        showDescription
        showDirectionsButton
        showLegendOverlay
        showLightbox
        showListFilter
        showListOverlay
        showModals
        showSearchbox
        showSidebar
        showSidebarAddress
        showTagsInModals
        showTagsInSidebar
        showTitle
        showTitleDescriptionOverlay
        showTraffic
        showTransit
        sidebarAccentColor
        sidebarBackgroundColor
        sidebarDescriptionSize
        sidebarDescriptionWeight
        sidebarPosition
        sidebarTitleSize
        sidebarTitleWeight
        sidebarWidth
        status
        streetViewControl
        tagBehavior
        tagsOrder
        titleImage
        unitSystem
        updatedAt
        zoomControl
        zoomHandling
        __typename
      }
      comments {
        items {
          id
          owner
          content
          createdAt
          updatedAt
          markerCommentsId
          __typename
        }
        nextToken
        __typename
      }
      images {
        items {
          id
          imageMarkerId
          image
          name
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      border
      borderColor
      buttonLink
      color
      createdAt
      customIcon
      customImage
      customStyle
      formattedAddress
      hideButton
      icon
      iconColor
      imagesOrder
      lat
      long
      markerNameBackgroundColor
      markerNameBackgroundOn
      markerNameColor
      markerNameOn
      markerNamePosition
      name
      notes
      placeId
      shape
      size
      tags
      updatedAt
      useCoordinates
      useHTML
      __typename
    }
  }
`;
export const createImage = /* GraphQL */ `
  mutation CreateImage(
    $input: CreateImageInput!
    $condition: ModelImageConditionInput
  ) {
    createImage(input: $input, condition: $condition) {
      id
      imageMarkerId
      marker {
        id
        markerMapId
        map {
          id
          owner
          type
          version
          clusterColor
          clusterRadius
          clusterSize
          createdAt
          currentLocationText
          customCSS
          defaultRotate
          defaultTilt
          defaultZoom
          description
          descriptionHTML
          disableZoomToMarker
          embedDesktopHeight
          embedDesktopWidth
          embedMobileHeight
          embedMobileWidth
          filterPlaceholder
          fontPrimary
          fontSecondary
          fullscreenControl
          gestureHandling
          groupsCollapsed
          highlightSelectedMarker
          initialLatitude
          initialLongitude
          initialRotate
          initialTilt
          initialView
          initialZoom
          itemsOrder
          mapStyle
          mapStyleIcons
          mapStyleLabels
          mapStyleLandmarks
          mapStyleRoads
          mapTileType
          mapType
          markerBorder
          markerBorderColor
          markerColor
          markerCustomIcon
          markerCustomImage
          markerCustomStyle
          markerIcon
          markerIconColor
          markerNameBackgroundColor
          markerNameBackgroundOn
          markerNameColor
          markerNameOn
          markerNamePosition
          markerProgrammaticIconType
          markerShape
          markerSize
          modalAddressColor
          modalAddressLink
          modalAddressSize
          modalAddressVisible
          modalAddressWeight
          modalBackgroundColor
          modalButtonBackgroundColor
          modalButtonBorder
          modalButtonBorderColor
          modalButtonFontColor
          modalButtonFontSize
          modalButtonFontWeight
          modalButtonFullWidth
          modalButtonPadding
          modalButtonRounding
          modalButtons
          modalButtonText
          modalButtonType
          modalClose
          modalGoogleMapsButton
          modalGoogleMapsButtonText
          modalGoogleMapsButtonType
          modalImageHeight
          modalLinkButton
          modalLinkButtonText
          modalLinkSameWindow
          modalNoteColor
          modalNoteShowFull
          modalNoteSize
          modalNoteVisible
          modalNoteWeight
          modalPadding
          modalPosition
          modalRadius
          modalShadow
          modalTagBackgroundColor
          modalTagFontColor
          modalTagFontSize
          modalTagFullWidth
          modalTagPadding
          modalTagRounding
          modalTail
          modalTitleColor
          modalTitleSize
          modalTitleVisible
          modalTitleWeight
          modalTransparency
          modalWidth
          name
          overlayBorder
          overlayBorderSize
          overlayMargin
          overlayPadding
          overlayRounding
          overlayShadow
          overlayTransparency
          password
          permission
          rounding
          scaleControl
          searchBehavior
          searchPlaceholder
          showBicycling
          showBranding
          showCurrentLocation
          showDescription
          showDirectionsButton
          showLegendOverlay
          showLightbox
          showListFilter
          showListOverlay
          showModals
          showSearchbox
          showSidebar
          showSidebarAddress
          showTagsInModals
          showTagsInSidebar
          showTitle
          showTitleDescriptionOverlay
          showTraffic
          showTransit
          sidebarAccentColor
          sidebarBackgroundColor
          sidebarDescriptionSize
          sidebarDescriptionWeight
          sidebarPosition
          sidebarTitleSize
          sidebarTitleWeight
          sidebarWidth
          status
          streetViewControl
          tagBehavior
          tagsOrder
          titleImage
          unitSystem
          updatedAt
          zoomControl
          zoomHandling
          __typename
        }
        comments {
          nextToken
          __typename
        }
        images {
          nextToken
          __typename
        }
        border
        borderColor
        buttonLink
        color
        createdAt
        customIcon
        customImage
        customStyle
        formattedAddress
        hideButton
        icon
        iconColor
        imagesOrder
        lat
        long
        markerNameBackgroundColor
        markerNameBackgroundOn
        markerNameColor
        markerNameOn
        markerNamePosition
        name
        notes
        placeId
        shape
        size
        tags
        updatedAt
        useCoordinates
        useHTML
        __typename
      }
      image
      name
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateImage = /* GraphQL */ `
  mutation UpdateImage(
    $input: UpdateImageInput!
    $condition: ModelImageConditionInput
  ) {
    updateImage(input: $input, condition: $condition) {
      id
      imageMarkerId
      marker {
        id
        markerMapId
        map {
          id
          owner
          type
          version
          clusterColor
          clusterRadius
          clusterSize
          createdAt
          currentLocationText
          customCSS
          defaultRotate
          defaultTilt
          defaultZoom
          description
          descriptionHTML
          disableZoomToMarker
          embedDesktopHeight
          embedDesktopWidth
          embedMobileHeight
          embedMobileWidth
          filterPlaceholder
          fontPrimary
          fontSecondary
          fullscreenControl
          gestureHandling
          groupsCollapsed
          highlightSelectedMarker
          initialLatitude
          initialLongitude
          initialRotate
          initialTilt
          initialView
          initialZoom
          itemsOrder
          mapStyle
          mapStyleIcons
          mapStyleLabels
          mapStyleLandmarks
          mapStyleRoads
          mapTileType
          mapType
          markerBorder
          markerBorderColor
          markerColor
          markerCustomIcon
          markerCustomImage
          markerCustomStyle
          markerIcon
          markerIconColor
          markerNameBackgroundColor
          markerNameBackgroundOn
          markerNameColor
          markerNameOn
          markerNamePosition
          markerProgrammaticIconType
          markerShape
          markerSize
          modalAddressColor
          modalAddressLink
          modalAddressSize
          modalAddressVisible
          modalAddressWeight
          modalBackgroundColor
          modalButtonBackgroundColor
          modalButtonBorder
          modalButtonBorderColor
          modalButtonFontColor
          modalButtonFontSize
          modalButtonFontWeight
          modalButtonFullWidth
          modalButtonPadding
          modalButtonRounding
          modalButtons
          modalButtonText
          modalButtonType
          modalClose
          modalGoogleMapsButton
          modalGoogleMapsButtonText
          modalGoogleMapsButtonType
          modalImageHeight
          modalLinkButton
          modalLinkButtonText
          modalLinkSameWindow
          modalNoteColor
          modalNoteShowFull
          modalNoteSize
          modalNoteVisible
          modalNoteWeight
          modalPadding
          modalPosition
          modalRadius
          modalShadow
          modalTagBackgroundColor
          modalTagFontColor
          modalTagFontSize
          modalTagFullWidth
          modalTagPadding
          modalTagRounding
          modalTail
          modalTitleColor
          modalTitleSize
          modalTitleVisible
          modalTitleWeight
          modalTransparency
          modalWidth
          name
          overlayBorder
          overlayBorderSize
          overlayMargin
          overlayPadding
          overlayRounding
          overlayShadow
          overlayTransparency
          password
          permission
          rounding
          scaleControl
          searchBehavior
          searchPlaceholder
          showBicycling
          showBranding
          showCurrentLocation
          showDescription
          showDirectionsButton
          showLegendOverlay
          showLightbox
          showListFilter
          showListOverlay
          showModals
          showSearchbox
          showSidebar
          showSidebarAddress
          showTagsInModals
          showTagsInSidebar
          showTitle
          showTitleDescriptionOverlay
          showTraffic
          showTransit
          sidebarAccentColor
          sidebarBackgroundColor
          sidebarDescriptionSize
          sidebarDescriptionWeight
          sidebarPosition
          sidebarTitleSize
          sidebarTitleWeight
          sidebarWidth
          status
          streetViewControl
          tagBehavior
          tagsOrder
          titleImage
          unitSystem
          updatedAt
          zoomControl
          zoomHandling
          __typename
        }
        comments {
          nextToken
          __typename
        }
        images {
          nextToken
          __typename
        }
        border
        borderColor
        buttonLink
        color
        createdAt
        customIcon
        customImage
        customStyle
        formattedAddress
        hideButton
        icon
        iconColor
        imagesOrder
        lat
        long
        markerNameBackgroundColor
        markerNameBackgroundOn
        markerNameColor
        markerNameOn
        markerNamePosition
        name
        notes
        placeId
        shape
        size
        tags
        updatedAt
        useCoordinates
        useHTML
        __typename
      }
      image
      name
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteImage = /* GraphQL */ `
  mutation DeleteImage(
    $input: DeleteImageInput!
    $condition: ModelImageConditionInput
  ) {
    deleteImage(input: $input, condition: $condition) {
      id
      imageMarkerId
      marker {
        id
        markerMapId
        map {
          id
          owner
          type
          version
          clusterColor
          clusterRadius
          clusterSize
          createdAt
          currentLocationText
          customCSS
          defaultRotate
          defaultTilt
          defaultZoom
          description
          descriptionHTML
          disableZoomToMarker
          embedDesktopHeight
          embedDesktopWidth
          embedMobileHeight
          embedMobileWidth
          filterPlaceholder
          fontPrimary
          fontSecondary
          fullscreenControl
          gestureHandling
          groupsCollapsed
          highlightSelectedMarker
          initialLatitude
          initialLongitude
          initialRotate
          initialTilt
          initialView
          initialZoom
          itemsOrder
          mapStyle
          mapStyleIcons
          mapStyleLabels
          mapStyleLandmarks
          mapStyleRoads
          mapTileType
          mapType
          markerBorder
          markerBorderColor
          markerColor
          markerCustomIcon
          markerCustomImage
          markerCustomStyle
          markerIcon
          markerIconColor
          markerNameBackgroundColor
          markerNameBackgroundOn
          markerNameColor
          markerNameOn
          markerNamePosition
          markerProgrammaticIconType
          markerShape
          markerSize
          modalAddressColor
          modalAddressLink
          modalAddressSize
          modalAddressVisible
          modalAddressWeight
          modalBackgroundColor
          modalButtonBackgroundColor
          modalButtonBorder
          modalButtonBorderColor
          modalButtonFontColor
          modalButtonFontSize
          modalButtonFontWeight
          modalButtonFullWidth
          modalButtonPadding
          modalButtonRounding
          modalButtons
          modalButtonText
          modalButtonType
          modalClose
          modalGoogleMapsButton
          modalGoogleMapsButtonText
          modalGoogleMapsButtonType
          modalImageHeight
          modalLinkButton
          modalLinkButtonText
          modalLinkSameWindow
          modalNoteColor
          modalNoteShowFull
          modalNoteSize
          modalNoteVisible
          modalNoteWeight
          modalPadding
          modalPosition
          modalRadius
          modalShadow
          modalTagBackgroundColor
          modalTagFontColor
          modalTagFontSize
          modalTagFullWidth
          modalTagPadding
          modalTagRounding
          modalTail
          modalTitleColor
          modalTitleSize
          modalTitleVisible
          modalTitleWeight
          modalTransparency
          modalWidth
          name
          overlayBorder
          overlayBorderSize
          overlayMargin
          overlayPadding
          overlayRounding
          overlayShadow
          overlayTransparency
          password
          permission
          rounding
          scaleControl
          searchBehavior
          searchPlaceholder
          showBicycling
          showBranding
          showCurrentLocation
          showDescription
          showDirectionsButton
          showLegendOverlay
          showLightbox
          showListFilter
          showListOverlay
          showModals
          showSearchbox
          showSidebar
          showSidebarAddress
          showTagsInModals
          showTagsInSidebar
          showTitle
          showTitleDescriptionOverlay
          showTraffic
          showTransit
          sidebarAccentColor
          sidebarBackgroundColor
          sidebarDescriptionSize
          sidebarDescriptionWeight
          sidebarPosition
          sidebarTitleSize
          sidebarTitleWeight
          sidebarWidth
          status
          streetViewControl
          tagBehavior
          tagsOrder
          titleImage
          unitSystem
          updatedAt
          zoomControl
          zoomHandling
          __typename
        }
        comments {
          nextToken
          __typename
        }
        images {
          nextToken
          __typename
        }
        border
        borderColor
        buttonLink
        color
        createdAt
        customIcon
        customImage
        customStyle
        formattedAddress
        hideButton
        icon
        iconColor
        imagesOrder
        lat
        long
        markerNameBackgroundColor
        markerNameBackgroundOn
        markerNameColor
        markerNameOn
        markerNamePosition
        name
        notes
        placeId
        shape
        size
        tags
        updatedAt
        useCoordinates
        useHTML
        __typename
      }
      image
      name
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createUserDetail = /* GraphQL */ `
  mutation CreateUserDetail(
    $input: CreateUserDetailInput!
    $condition: ModelUserDetailConditionInput
  ) {
    createUserDetail(input: $input, condition: $condition) {
      id
      type
      sub
      username
      email
      stripeCustomerId
      stripeSubscriptionId
      pro
      proType
      isOnMeteredBilling
      campaign
      cancelAt
      cancelledAt
      createdAt
      currentPeriodEnd
      currentPeriodStart
      currentUsagePeriodStart
      emailVerified
      mapLimit
      markerLimit
      reportedCurrentUsage
      reportedUsageAt
      status
      stripeMapViewsId
      trialExpireAt
      updatedAt
      upgradedAt
      __typename
    }
  }
`;
export const updateUserDetail = /* GraphQL */ `
  mutation UpdateUserDetail(
    $input: UpdateUserDetailInput!
    $condition: ModelUserDetailConditionInput
  ) {
    updateUserDetail(input: $input, condition: $condition) {
      id
      type
      sub
      username
      email
      stripeCustomerId
      stripeSubscriptionId
      pro
      proType
      isOnMeteredBilling
      campaign
      cancelAt
      cancelledAt
      createdAt
      currentPeriodEnd
      currentPeriodStart
      currentUsagePeriodStart
      emailVerified
      mapLimit
      markerLimit
      reportedCurrentUsage
      reportedUsageAt
      status
      stripeMapViewsId
      trialExpireAt
      updatedAt
      upgradedAt
      __typename
    }
  }
`;
export const deleteUserDetail = /* GraphQL */ `
  mutation DeleteUserDetail(
    $input: DeleteUserDetailInput!
    $condition: ModelUserDetailConditionInput
  ) {
    deleteUserDetail(input: $input, condition: $condition) {
      id
      type
      sub
      username
      email
      stripeCustomerId
      stripeSubscriptionId
      pro
      proType
      isOnMeteredBilling
      campaign
      cancelAt
      cancelledAt
      createdAt
      currentPeriodEnd
      currentPeriodStart
      currentUsagePeriodStart
      emailVerified
      mapLimit
      markerLimit
      reportedCurrentUsage
      reportedUsageAt
      status
      stripeMapViewsId
      trialExpireAt
      updatedAt
      upgradedAt
      __typename
    }
  }
`;
export const createRole = /* GraphQL */ `
  mutation CreateRole(
    $input: CreateRoleInput!
    $condition: ModelRoleConditionInput
  ) {
    createRole(input: $input, condition: $condition) {
      id
      sub
      username
      role
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateRole = /* GraphQL */ `
  mutation UpdateRole(
    $input: UpdateRoleInput!
    $condition: ModelRoleConditionInput
  ) {
    updateRole(input: $input, condition: $condition) {
      id
      sub
      username
      role
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteRole = /* GraphQL */ `
  mutation DeleteRole(
    $input: DeleteRoleInput!
    $condition: ModelRoleConditionInput
  ) {
    deleteRole(input: $input, condition: $condition) {
      id
      sub
      username
      role
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createComment = /* GraphQL */ `
  mutation CreateComment(
    $input: CreateCommentInput!
    $condition: ModelCommentConditionInput
  ) {
    createComment(input: $input, condition: $condition) {
      id
      owner
      content
      marker {
        id
        markerMapId
        map {
          id
          owner
          type
          version
          clusterColor
          clusterRadius
          clusterSize
          createdAt
          currentLocationText
          customCSS
          defaultRotate
          defaultTilt
          defaultZoom
          description
          descriptionHTML
          disableZoomToMarker
          embedDesktopHeight
          embedDesktopWidth
          embedMobileHeight
          embedMobileWidth
          filterPlaceholder
          fontPrimary
          fontSecondary
          fullscreenControl
          gestureHandling
          groupsCollapsed
          highlightSelectedMarker
          initialLatitude
          initialLongitude
          initialRotate
          initialTilt
          initialView
          initialZoom
          itemsOrder
          mapStyle
          mapStyleIcons
          mapStyleLabels
          mapStyleLandmarks
          mapStyleRoads
          mapTileType
          mapType
          markerBorder
          markerBorderColor
          markerColor
          markerCustomIcon
          markerCustomImage
          markerCustomStyle
          markerIcon
          markerIconColor
          markerNameBackgroundColor
          markerNameBackgroundOn
          markerNameColor
          markerNameOn
          markerNamePosition
          markerProgrammaticIconType
          markerShape
          markerSize
          modalAddressColor
          modalAddressLink
          modalAddressSize
          modalAddressVisible
          modalAddressWeight
          modalBackgroundColor
          modalButtonBackgroundColor
          modalButtonBorder
          modalButtonBorderColor
          modalButtonFontColor
          modalButtonFontSize
          modalButtonFontWeight
          modalButtonFullWidth
          modalButtonPadding
          modalButtonRounding
          modalButtons
          modalButtonText
          modalButtonType
          modalClose
          modalGoogleMapsButton
          modalGoogleMapsButtonText
          modalGoogleMapsButtonType
          modalImageHeight
          modalLinkButton
          modalLinkButtonText
          modalLinkSameWindow
          modalNoteColor
          modalNoteShowFull
          modalNoteSize
          modalNoteVisible
          modalNoteWeight
          modalPadding
          modalPosition
          modalRadius
          modalShadow
          modalTagBackgroundColor
          modalTagFontColor
          modalTagFontSize
          modalTagFullWidth
          modalTagPadding
          modalTagRounding
          modalTail
          modalTitleColor
          modalTitleSize
          modalTitleVisible
          modalTitleWeight
          modalTransparency
          modalWidth
          name
          overlayBorder
          overlayBorderSize
          overlayMargin
          overlayPadding
          overlayRounding
          overlayShadow
          overlayTransparency
          password
          permission
          rounding
          scaleControl
          searchBehavior
          searchPlaceholder
          showBicycling
          showBranding
          showCurrentLocation
          showDescription
          showDirectionsButton
          showLegendOverlay
          showLightbox
          showListFilter
          showListOverlay
          showModals
          showSearchbox
          showSidebar
          showSidebarAddress
          showTagsInModals
          showTagsInSidebar
          showTitle
          showTitleDescriptionOverlay
          showTraffic
          showTransit
          sidebarAccentColor
          sidebarBackgroundColor
          sidebarDescriptionSize
          sidebarDescriptionWeight
          sidebarPosition
          sidebarTitleSize
          sidebarTitleWeight
          sidebarWidth
          status
          streetViewControl
          tagBehavior
          tagsOrder
          titleImage
          unitSystem
          updatedAt
          zoomControl
          zoomHandling
          __typename
        }
        comments {
          nextToken
          __typename
        }
        images {
          nextToken
          __typename
        }
        border
        borderColor
        buttonLink
        color
        createdAt
        customIcon
        customImage
        customStyle
        formattedAddress
        hideButton
        icon
        iconColor
        imagesOrder
        lat
        long
        markerNameBackgroundColor
        markerNameBackgroundOn
        markerNameColor
        markerNameOn
        markerNamePosition
        name
        notes
        placeId
        shape
        size
        tags
        updatedAt
        useCoordinates
        useHTML
        __typename
      }
      createdAt
      updatedAt
      markerCommentsId
      __typename
    }
  }
`;
export const updateComment = /* GraphQL */ `
  mutation UpdateComment(
    $input: UpdateCommentInput!
    $condition: ModelCommentConditionInput
  ) {
    updateComment(input: $input, condition: $condition) {
      id
      owner
      content
      marker {
        id
        markerMapId
        map {
          id
          owner
          type
          version
          clusterColor
          clusterRadius
          clusterSize
          createdAt
          currentLocationText
          customCSS
          defaultRotate
          defaultTilt
          defaultZoom
          description
          descriptionHTML
          disableZoomToMarker
          embedDesktopHeight
          embedDesktopWidth
          embedMobileHeight
          embedMobileWidth
          filterPlaceholder
          fontPrimary
          fontSecondary
          fullscreenControl
          gestureHandling
          groupsCollapsed
          highlightSelectedMarker
          initialLatitude
          initialLongitude
          initialRotate
          initialTilt
          initialView
          initialZoom
          itemsOrder
          mapStyle
          mapStyleIcons
          mapStyleLabels
          mapStyleLandmarks
          mapStyleRoads
          mapTileType
          mapType
          markerBorder
          markerBorderColor
          markerColor
          markerCustomIcon
          markerCustomImage
          markerCustomStyle
          markerIcon
          markerIconColor
          markerNameBackgroundColor
          markerNameBackgroundOn
          markerNameColor
          markerNameOn
          markerNamePosition
          markerProgrammaticIconType
          markerShape
          markerSize
          modalAddressColor
          modalAddressLink
          modalAddressSize
          modalAddressVisible
          modalAddressWeight
          modalBackgroundColor
          modalButtonBackgroundColor
          modalButtonBorder
          modalButtonBorderColor
          modalButtonFontColor
          modalButtonFontSize
          modalButtonFontWeight
          modalButtonFullWidth
          modalButtonPadding
          modalButtonRounding
          modalButtons
          modalButtonText
          modalButtonType
          modalClose
          modalGoogleMapsButton
          modalGoogleMapsButtonText
          modalGoogleMapsButtonType
          modalImageHeight
          modalLinkButton
          modalLinkButtonText
          modalLinkSameWindow
          modalNoteColor
          modalNoteShowFull
          modalNoteSize
          modalNoteVisible
          modalNoteWeight
          modalPadding
          modalPosition
          modalRadius
          modalShadow
          modalTagBackgroundColor
          modalTagFontColor
          modalTagFontSize
          modalTagFullWidth
          modalTagPadding
          modalTagRounding
          modalTail
          modalTitleColor
          modalTitleSize
          modalTitleVisible
          modalTitleWeight
          modalTransparency
          modalWidth
          name
          overlayBorder
          overlayBorderSize
          overlayMargin
          overlayPadding
          overlayRounding
          overlayShadow
          overlayTransparency
          password
          permission
          rounding
          scaleControl
          searchBehavior
          searchPlaceholder
          showBicycling
          showBranding
          showCurrentLocation
          showDescription
          showDirectionsButton
          showLegendOverlay
          showLightbox
          showListFilter
          showListOverlay
          showModals
          showSearchbox
          showSidebar
          showSidebarAddress
          showTagsInModals
          showTagsInSidebar
          showTitle
          showTitleDescriptionOverlay
          showTraffic
          showTransit
          sidebarAccentColor
          sidebarBackgroundColor
          sidebarDescriptionSize
          sidebarDescriptionWeight
          sidebarPosition
          sidebarTitleSize
          sidebarTitleWeight
          sidebarWidth
          status
          streetViewControl
          tagBehavior
          tagsOrder
          titleImage
          unitSystem
          updatedAt
          zoomControl
          zoomHandling
          __typename
        }
        comments {
          nextToken
          __typename
        }
        images {
          nextToken
          __typename
        }
        border
        borderColor
        buttonLink
        color
        createdAt
        customIcon
        customImage
        customStyle
        formattedAddress
        hideButton
        icon
        iconColor
        imagesOrder
        lat
        long
        markerNameBackgroundColor
        markerNameBackgroundOn
        markerNameColor
        markerNameOn
        markerNamePosition
        name
        notes
        placeId
        shape
        size
        tags
        updatedAt
        useCoordinates
        useHTML
        __typename
      }
      createdAt
      updatedAt
      markerCommentsId
      __typename
    }
  }
`;
export const deleteComment = /* GraphQL */ `
  mutation DeleteComment(
    $input: DeleteCommentInput!
    $condition: ModelCommentConditionInput
  ) {
    deleteComment(input: $input, condition: $condition) {
      id
      owner
      content
      marker {
        id
        markerMapId
        map {
          id
          owner
          type
          version
          clusterColor
          clusterRadius
          clusterSize
          createdAt
          currentLocationText
          customCSS
          defaultRotate
          defaultTilt
          defaultZoom
          description
          descriptionHTML
          disableZoomToMarker
          embedDesktopHeight
          embedDesktopWidth
          embedMobileHeight
          embedMobileWidth
          filterPlaceholder
          fontPrimary
          fontSecondary
          fullscreenControl
          gestureHandling
          groupsCollapsed
          highlightSelectedMarker
          initialLatitude
          initialLongitude
          initialRotate
          initialTilt
          initialView
          initialZoom
          itemsOrder
          mapStyle
          mapStyleIcons
          mapStyleLabels
          mapStyleLandmarks
          mapStyleRoads
          mapTileType
          mapType
          markerBorder
          markerBorderColor
          markerColor
          markerCustomIcon
          markerCustomImage
          markerCustomStyle
          markerIcon
          markerIconColor
          markerNameBackgroundColor
          markerNameBackgroundOn
          markerNameColor
          markerNameOn
          markerNamePosition
          markerProgrammaticIconType
          markerShape
          markerSize
          modalAddressColor
          modalAddressLink
          modalAddressSize
          modalAddressVisible
          modalAddressWeight
          modalBackgroundColor
          modalButtonBackgroundColor
          modalButtonBorder
          modalButtonBorderColor
          modalButtonFontColor
          modalButtonFontSize
          modalButtonFontWeight
          modalButtonFullWidth
          modalButtonPadding
          modalButtonRounding
          modalButtons
          modalButtonText
          modalButtonType
          modalClose
          modalGoogleMapsButton
          modalGoogleMapsButtonText
          modalGoogleMapsButtonType
          modalImageHeight
          modalLinkButton
          modalLinkButtonText
          modalLinkSameWindow
          modalNoteColor
          modalNoteShowFull
          modalNoteSize
          modalNoteVisible
          modalNoteWeight
          modalPadding
          modalPosition
          modalRadius
          modalShadow
          modalTagBackgroundColor
          modalTagFontColor
          modalTagFontSize
          modalTagFullWidth
          modalTagPadding
          modalTagRounding
          modalTail
          modalTitleColor
          modalTitleSize
          modalTitleVisible
          modalTitleWeight
          modalTransparency
          modalWidth
          name
          overlayBorder
          overlayBorderSize
          overlayMargin
          overlayPadding
          overlayRounding
          overlayShadow
          overlayTransparency
          password
          permission
          rounding
          scaleControl
          searchBehavior
          searchPlaceholder
          showBicycling
          showBranding
          showCurrentLocation
          showDescription
          showDirectionsButton
          showLegendOverlay
          showLightbox
          showListFilter
          showListOverlay
          showModals
          showSearchbox
          showSidebar
          showSidebarAddress
          showTagsInModals
          showTagsInSidebar
          showTitle
          showTitleDescriptionOverlay
          showTraffic
          showTransit
          sidebarAccentColor
          sidebarBackgroundColor
          sidebarDescriptionSize
          sidebarDescriptionWeight
          sidebarPosition
          sidebarTitleSize
          sidebarTitleWeight
          sidebarWidth
          status
          streetViewControl
          tagBehavior
          tagsOrder
          titleImage
          unitSystem
          updatedAt
          zoomControl
          zoomHandling
          __typename
        }
        comments {
          nextToken
          __typename
        }
        images {
          nextToken
          __typename
        }
        border
        borderColor
        buttonLink
        color
        createdAt
        customIcon
        customImage
        customStyle
        formattedAddress
        hideButton
        icon
        iconColor
        imagesOrder
        lat
        long
        markerNameBackgroundColor
        markerNameBackgroundOn
        markerNameColor
        markerNameOn
        markerNamePosition
        name
        notes
        placeId
        shape
        size
        tags
        updatedAt
        useCoordinates
        useHTML
        __typename
      }
      createdAt
      updatedAt
      markerCommentsId
      __typename
    }
  }
`;
